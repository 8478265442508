import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, OutlinedInput } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DatePicker from "react-datepicker";
import '../requisitionReports.css'
import getAllRequisitionStatus from '../../../apis/getallRequisitionStatus/getAllRequisitionStatus';
import { NotificationManager } from "react-notifications";
import jsonConfig from "../../../Config.json"



const JobReportSearch = ({ allDivisionNames, setAdditionalFilter_activity, allClientNames, setIsfilterSearch, setAdditionalFilter, selectedTab, setAdditionalFilter_submission, setAdditionalFilter_workReport, setAdditionalFilter_recruiter_activity, setFilterValues_accessreport }) => {
    const [formValues, setFormValues] = useState({
        divisionId: [],
        clientName: [],
        requisitionStatus: [],
        requistionSubStatus: [],
        placementType: [],
        start_date: {
            from: "",
            to: ""
        },
        offer_release_date: {
            from: "",
            to: ""
        },
        interview_date: {
            from: "",
            to: ""
        },
        email: [],
        ipAddress: [],
    });
    const [openSubList, setOpenSubList] = useState([]);
    const [statusNames, setStatusNames] = useState([]);
    const [placementTypeOption, setPlacementTypeOption] = useState([
        { PositionType: "Direct Placement" }, { PositionType: "Right To Hire" }, { PositionType: "Full Time" }, { PositionType: "Contract" }
    ]);


    useEffect(() => {
        setFormValues({
            divisionId: [],
            clientName: [],
            requisitionStatus: [],
            requistionSubStatus: [],
            placementType: [],
            start_date: {
                from: "",
                to: ""
            },
            offer_release_date: {
                from: "",
                to: ""
            },
            interview_date: {
                from: "",
                to: ""
            },
            email: [],
            ipAddress: [],
        })
    }, [selectedTab])



    useEffect(() => {
        const fetchStatusNames = async () => {
            try {
                const response = await getAllRequisitionStatus();
                const statusData = response?.data?.data || [];

                // Set all status names to the state
                setStatusNames(statusData);

                // Filter to find the "OPEN" status and set its sublist
                const openStatus = statusData.find(status => status.name === "OPEN");

                // Log results for debugging
                // console.log({ openStatus });
                // console.log({ statusData });

                // Set sublist if "OPEN" is found, otherwise clear it
                setOpenSubList(openStatus?.subList || []);
            } catch (error) {
                console.error("Error fetching requisition status:", error);
            }
        };

        fetchStatusNames();
    }, []);


    // console.log({ statusNames })
    // console.log({ openSubList })

    let is_OPEN_Exist = formValues.requisitionStatus?.some(item => item === "OPEN") || false;
    useEffect(() => {
        // console.log({ is_OPEN_Exist })
        if (!is_OPEN_Exist) {
            setFormValues(prevValues => ({
                ...prevValues,
                requistionSubStatus: [],


            }));
        }
    }, [is_OPEN_Exist])
    const subStatusName = Object.entries(jsonConfig.requisitionStatusCodes.OPENSUBSTATUS)

    const handleAutocompleteChange = (event, newValue, fieldName) => {
        if (fieldName === "requisitionStatus") {
            // Map newValue to an array of status names (strings)
            const newValueArray = newValue.map(item => item.name);
            setFormValues(prevValues => ({
                ...prevValues,
                [fieldName]: newValueArray,
            }));
        } else if (fieldName === "requisition_SubStatus" && is_OPEN_Exist) {
            // Ensure that newValue is an array before using .map()
            const subStatusCodesArray = Array.isArray(newValue)
                ? newValue.map(item => {
                    // Find the corresponding code for the subName
                    const match = subStatusName.find(([name]) => name === item.subName);
                    return match ? match[1] : item.subName; // Return the code or the subName itself if no match
                })
                : [];

            setFormValues(prevValues => ({
                ...prevValues,
                requistionSubStatus: subStatusCodesArray, // Store only substatus codes as an array of strings
            }));
        } else if (fieldName === "placementType") {
            // Handle placementType similarly
            const newValueArray = newValue.map(item => item.PositionType);
            setFormValues(prevValues => ({
                ...prevValues,
                [fieldName]: newValueArray,
            }));
        } else {
            // Default case for other fields
            setFormValues(prevValues => ({
                ...prevValues,
                [fieldName]: newValue,
            }));
        }

        // console.log("Updated Form Values:", formValues);
    };


    const handleDivisionChange = (event, newValue) => {
        // Extract the _id values from the selected options

        const divisionIds = newValue.map(option => option._id);
        handleAutocompleteChange(event, divisionIds, 'divisionId')


        // const divisionIds = newValue.map(option => option.divisionName);

        // handleAutocompleteChange(event, divisionIds, 'divisionId')

    };

    const handleIPChange = (event) => {
        const { value } = event.target;
        if (value.trim() === "") {

            setFormValues((prevState) => ({
                ...prevState, ipAddress: [],
            }));
            return;

        }

        setFormValues((prevState) => ({
            ...prevState, ipAddress: [value],
        }));

    };


    const handleEmailChange = (event) => {
        const { value } = event.target;
        if (value.trim() === "") {
            setFormValues((prevState) => ({
                ...prevState, email: [],
            }));
            return;

        }

        setFormValues((prevState) => ({
            ...prevState, email: [value],
        }));

    };

    const handleClientNameChange = (event, newValue) => {
        // Extract the _id values from the selected options
        const clientNames = newValue.map(option => option._id);
        handleAutocompleteChange(event, clientNames, 'clientName');
    };

    const handleDateChange = (date, field, subField) => {
        if (date instanceof Date && !isNaN(date)) {
            const formattedDate = date.toISOString().split('T')[0]; // Formats to yyyy-mm-dd
            setFormValues(prevValues => ({
                ...prevValues,
                [field]: {
                    ...prevValues[field],
                    [subField]: formattedDate
                }
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [field]: {
                    ...prevValues[field],
                    [subField]: ""
                }
            }));
        }
    };


    const handleActivityEmail = (event) => {
        const { value } = event.target;
        if (value.trim() === "") {
            setFormValues((prevState) => ({
                ...prevState, email: [],
            }));
            return;

        }

        setFormValues((prevState) => ({
            ...prevState, email: value,
        }));

    }

    const handleActivityName = (event) => {
        const { value } = event.target;
        if (value.trim() === "") {
            setFormValues((prevState) => ({
                ...prevState, name: "",
            }));
            return;

        }

        setFormValues((prevState) => ({
            ...prevState, name: value,
        }));

    }


    // console.log({ formValues })

    const validateDateRange = (from, to, fieldName) => {
        const fieldNameMapping = {
            start_date: "Start Date",
            offer_release_date: "Offer Release Date",
            interview_date: "Interview Date"
        };

        // Skip validation if either 'from' or 'to' is an  --> ""
        if (!from || !to) {
            return true; // No validation needed if either value is empty
        }

        if (new Date(from) >= new Date(to)) {
            // Get th  name from the mapping || or fall back to the fieldName if not found
            const displayName = fieldNameMapping[fieldName] || fieldName;
            NotificationManager.error(`Please enter a valid date in ${displayName}`, "Error", 2500);

            return false;
        }
        return true;
    };


    const handleSearch = () => {
        // Validate all date ranges using the helper function
        const dateFields = [
            { from: formValues.start_date?.from, to: formValues.start_date?.to, fieldName: 'start_date' },
            { from: formValues.offer_release_date?.from, to: formValues.offer_release_date?.to, fieldName: 'offer_release_date' },
            { from: formValues.interview_date?.from, to: formValues.interview_date?.to, fieldName: 'interview_date' }
        ];

        for (const { from, to, fieldName } of dateFields) {
            if (!validateDateRange(from, to, fieldName)) {
                return; // Exit early if any date validation fails
            }
        }


        setIsfilterSearch(true);
        if (selectedTab === "Job Report") {
            setAdditionalFilter(prevFilter => ({
                ...prevFilter,
                placementType: formValues.placementType,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
                requisitionStatus: formValues.requisitionStatus,
                requisionSubStatus: formValues.requistionSubStatus,
                start_date: formValues.start_date,
                offer_release_date: formValues.offer_release_date,
                interview_date: formValues.interview_date
            }));
        }
        else if (selectedTab === "Submission Report") {
            setAdditionalFilter_submission(prevFilter => ({
                ...prevFilter,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
                start_date: formValues.start_date,
                offer_release_date: formValues.offer_release_date,
                interview_date: formValues.interview_date
            }));
        }
        else if (selectedTab === "Work Report") {
            setAdditionalFilter_workReport(prevFilter => ({
                ...prevFilter,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
                start_date: formValues.start_date,
                offer_release_date: formValues.offer_release_date,
                interview_date: formValues.interview_date
            }))
        }
        else if (selectedTab === "Recruiter's Activity") {
            setAdditionalFilter_recruiter_activity(prevFilter => ({
                ...prevFilter,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
            }))
        } else if (selectedTab === "Hiring Report") {
            setAdditionalFilter(prevFilter => ({
                ...prevFilter,
                placementType: formValues.placementType,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId
            }));
        }
        else if (selectedTab === "Access log Report") {
            setFilterValues_accessreport(prevFilter => ({
                ...prevFilter,
                email: formValues.email,
                ipAddress: formValues.ipAddress

            }));
        }

        else if (selectedTab === "Activity Report") {
            setAdditionalFilter_activity(prevFilter => ({
                ...prevFilter,
                email: formValues.email.length === 0 ? "" : formValues.email

            }));
        }


    };


    return (
        <Box className='search_main_Container' >
            <Box className='search_btn_box'>
                <div className='text_top'>
                    <Typography className=''>Showing Results for</Typography>
                    <Typography style={{ color: 'gray', marginTop: "2%" }}>{selectedTab}</Typography>
                </div>

                <div className='search_btn_top'>
                    <button className='search_btn' onClick={handleSearch}>Search</button>
                </div>
            </Box>
            {/* <Box className='current_tab_search_name'>
                <Typography >{selectedTab} Search</Typography>
            </Box> */}

            <Box sx={{ marginTop: "14px", padding: "5px 4px", height: "55vh" }} >
                {(selectedTab === "Job Report") && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>
                    {selectedTab !== "Hiring Report" &&

                        <>
                            <div style={{ marginBottom: "10px" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Requisition Status
                                </Typography>
                                <Autocomplete
                                    multiple
                                    options={statusNames}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'requisitionStatus')}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Status"
                                        />
                                    )}
                                />
                            </div>
                            {is_OPEN_Exist && (
                                <div style={{ marginBottom: "10px" }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Requisition Sub Status
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        options={openSubList}
                                        getOptionLabel={(option) => option.subName}
                                        onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'requisition_SubStatus')}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Sub Status"
                                            />
                                        )}
                                    />
                                </div>
                            )}

                        </>



                    }
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Placement Type
                        </Typography>
                        <Autocomplete
                            multiple
                            options={placementTypeOption}
                            getOptionLabel={(option) => option.PositionType}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'placementType')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Placement Type"
                                />
                            )}
                        />
                    </div>
                </form>)}


                {(selectedTab === "Hiring Report") && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>
                    {selectedTab !== "Hiring Report" &&

                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Requisition Status
                            </Typography>
                            <Autocomplete
                                multiple
                                options={statusNames}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'requisitionStatus')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Status"
                                    />
                                )}
                            />
                        </div>
                    }
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Placement Type
                        </Typography>
                        <Autocomplete
                            multiple
                            options={placementTypeOption}
                            getOptionLabel={(option) => option.PositionType}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'placementType')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Placement Type"
                                />
                            )}
                        />
                    </div>
                </form>)}
                {selectedTab === "Submission Report" && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>

                    <div className='createRequisition_datapicker'>
                        <Typography variant="subtitle1" gutterBottom >
                            Start Date
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' }}>
                            <div>
                                <Typography style={{ color: "grey" }} className="text_color_subheading" variant="subtitle2" gutterBottom >
                                    From
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.start_date.from ? new Date(formValues.start_date.from) : null}
                                    onChange={(date) => handleDateChange(date, 'start_date', 'from')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    To
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.start_date.to ? new Date(formValues.start_date.to) : null}
                                    onChange={(date) => handleDateChange(date, 'start_date', 'to')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                        </div>
                    </div>


                    <div style={{ marginBottom: "10px", marginTop: "15px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Offer Release Date
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' }}>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    From
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.offer_release_date.from ? new Date(formValues.offer_release_date.from) : null}
                                    onChange={(date) => handleDateChange(date, 'offer_release_date', 'from')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    To
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.offer_release_date.to ? new Date(formValues.offer_release_date.to) : null}
                                    onChange={(date) => handleDateChange(date, 'offer_release_date', 'to')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Interview Date
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' }}>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    From
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.interview_date.from ? new Date(formValues.interview_date.from) : null}
                                    onChange={(date) => handleDateChange(date, 'interview_date', 'from')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    To
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.interview_date.to ? new Date(formValues.interview_date.to) : null}
                                    onChange={(date) => handleDateChange(date, 'interview_date', 'to')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                        </div>
                    </div>
                </form>)
                }
                {selectedTab === "Work Report" && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>
                    <div className='createRequisition_datapicker'>
                        <Typography variant="subtitle1" gutterBottom>
                            Start Date
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' }}>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    From
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.start_date.from ? new Date(formValues.start_date.from) : null}
                                    onChange={(date) => handleDateChange(date, 'start_date', 'from')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    To
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.start_date.to ? new Date(formValues.start_date.to) : null}
                                    onChange={(date) => handleDateChange(date, 'start_date', 'to')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                        </div>
                    </div>


                    <div style={{ marginBottom: "10px", marginTop: "15px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Offer Release Date
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' }}>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    From
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.offer_release_date.from ? new Date(formValues.offer_release_date.from) : null}
                                    onChange={(date) => handleDateChange(date, 'offer_release_date', 'from')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    To
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.offer_release_date.to ? new Date(formValues.offer_release_date.to) : null}
                                    onChange={(date) => handleDateChange(date, 'offer_release_date', 'to')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Interview Date
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px' }}>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    From
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.interview_date.from ? new Date(formValues.interview_date.from) : null}
                                    onChange={(date) => handleDateChange(date, 'interview_date', 'from')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"

                                />
                            </div>
                            <div>
                                <Typography style={{ color: "grey" }} variant="subtitle2" gutterBottom>
                                    To
                                </Typography>
                                <DatePicker
                                    className="createRequisition_textbox_datapicker_reports_filter"
                                    selected={formValues.interview_date.to ? new Date(formValues.interview_date.to) : null}
                                    onChange={(date) => handleDateChange(date, 'interview_date', 'to')}
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="MM-DD-YYYY"
                                />
                            </div>
                        </div>
                    </div>
                </form>)
                }
                {
                    selectedTab === "Recruiter's Activity" && (<form>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Division
                            </Typography>
                            <Autocomplete
                                multiple
                                options={allDivisionNames}
                                getOptionLabel={(option) => option.divisionName}
                                onChange={handleDivisionChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Division"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Client Name
                            </Typography>
                            <Autocomplete
                                multiple
                                options={allClientNames}
                                getOptionLabel={(option) => option.clientName}
                                onChange={handleClientNameChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Client"
                                    />
                                )}
                            />
                        </div>
                    </form>)
                }
                {
                    (selectedTab === "Access log Report") && (<form>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Email
                            </Typography>

                            <TextField variant="outlined" placeholder="Enter Email" className='access_search' fullWidth onChange={handleEmailChange} />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Ip Address
                            </Typography>
                            <TextField variant="outlined" placeholder="Enter IP Address" className='access_search' fullWidth onChange={handleIPChange} />
                        </div>
                    </form>)
                }



                {
                    (selectedTab === "Activity Report") && (<form>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Email
                            </Typography>
                            <TextField variant="outlined" placeholder="Enter Email" className='access_search' fullWidth onChange={handleActivityEmail} onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();
                                }
                            }} />
                        </div>
                    </form>)
                }
            </Box >
        </Box >
    );
};

export default JobReportSearch;
