import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState, useContext } from 'react';
import getAllRequisitionByRecruiterId from '../../apis/getAllRequisitionByRecruiterId/getAllRequisitionByRecruiterId';
import EditIcon from "../../components/basicInfoForm/images/edit-icon.png";
import EditSharpIcon from '@material-ui/icons/EditSharp';
import getAllCandidateRequisitionStatus from '../../apis/getAllCandidateRequisitionStatus/getAllCandidateRequisitionStatus';
import profileResponse from '../../apis/profile/profileById';
import configJSON from "../../Config.json"
import "./ActivityModal.css"
import { NotificationManager } from "react-notifications";
import findCandidatePreviousRequisitionsForClient from '../../apis/findCandidatePreviousRequisitionsForClient/findCandidatePreviousRequisitionsForClient';
import createCandidateRequisition from '../../apis/createCandidateRequisition/createCandidateRequisition';
import updateCandidateRequisition from '../../apis/updateCandidateRequisition/updateCandidateRequisition';
import { convertDate, createEventStatus, fetchDydamicFields, getCanReq, getFilteredRequisitionStatus, verifyForm } from './assistingFunctions';
import pdfIcon from "../../pages/myTalentCommunity/icons/pdf.svg";
import docIcon from "../../pages/myTalentCommunity/icons/doc.png";
import UploadResumeModal from '../UploadResumeModal/UploadResumeModal';
import candidateResumeById from "../../apis/candidateResumeById/candidateResumeById";
import { UserTypeContext } from '../../AppRoute';
import { Button } from 'react-bootstrap';
import AutoComplete from '../AutoComplete/AutoComplete';
import { DateTime } from "luxon";
import { codeTofield, filterFunc, renderField } from '../GlobalAssistingFunctions/GlobalAssistingFunctions';
import getAllRecruiters from '../../apis/getAllRecruiters/getAllRecruiters';
import FormModel from '../formModel/FormModel';


// Button Styles for the Upload Resume button
const buttonStyles = {
    backgroundColor: '#1885e6',
    color: '#fff',
    padding: '10px 10px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
        color: '#fff'
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: '"Segoe UI Semibold", sans-serif !important',
        fontWeight: 'bold',
        fontSize: "10px",
        color: "#ffffff !important",
        textShadow: "0px 1px 2px black !important"
    }
}));


const ActivityModal = ({
    props,
    setisActivity,
    row_DataItem,
    setEdit,
    reqId,
    email,
    isActivity,
    classes,
    page = "",
    setReqId,
    setAllRequisitionData,
    setRecId,
    recId,
    moveCandidate = () => { }
}) => {

    const [allRequisition, setAllRequisition] = useState([])
    const [statusOptions, setStatusOptions] = useState([])
    const [orignalData, setOriginalData] = useState()
    const [requiredFields, setRequiredfields] = useState([])
    const [loader, setLoader] = useState({ loading: false, saving: false, next: false })
    const [resumeLinkData, setResumeLinkData] = useState('')
    const [resumeLinkDataLoader, setResumeLinkDataLoader] = useState(false)
    const { userType, userRecords } = useContext(UserTypeContext);
    const idContextTest = userType === "Admin" ? props?.location?.state?.id : userRecords?.id;
    const userRecruitmentpartnerId = userType === "Admin" ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
    const id = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
    const [showResumeModal, setShowResumeModal] = useState(false)
    const [isResumeUploadLoader, setIsResumeUploadLoader] = useState(false);
    const [isReferenceSelected, setIsReferenceSelected] = useState(false);
    const [submitDataFromModal, setSubmitDataFromModal] = useState();
    const styleClasses = useStyles();
    const [candidateReqId,setCandidateReqId]=useState()
    const [recruiters, setRecruiters] = useState([])
    const [formData, setFormData] = useState({
        TL: "",
        RL: "",
        gender: "",
        currentStatus: "",
        id: ""
    });
   

    
    const disabled = submitDataFromModal?.canReqExist && submitDataFromModal?.statusName === orignalData?.statusName
    const requiredFormFields = ["currentStatus", "gender", "RL", "TL"]
 
    const handleDataFromModal = (data) => {
        setResumeLinkData(data[0].URL);
    };

    // This callback function is used to handle the loader state of the UploadResumeModal component
    const handleResumeLoader = (loader) => {
        setIsResumeUploadLoader(loader);
        updateSubmitDataState('resumeUpdate', true)
    };




    //    fetch all requisitions for the recruiter
    const fetchRequisitionsByRecruiterId = async (recid) => {
        if (!recid)
            return

        // if (allRequisition?.length > 0)
        //     return allRequisition

        let payload = {
            isActivityHandler: true,
            statusCode: configJSON.requisitionStatusCodes.OPEN,
            subCode: configJSON.requisitionStatusCodes.OPENSUBSTATUS.UNCOVERED,
            recruitmentpartnerId: userRecruitmentpartnerId
        }

        const res = await getAllRequisitionByRecruiterId(recid, payload);
        setAllRequisition(res?.data?.data || []);
        setAllRequisitionData(res?.data?.data || [])
        return res?.data?.data

    };



    // Fetching all candiadte requisition status
    const getAllCandidateReqStatus = async () => {
        let resData = await getAllCandidateRequisitionStatus();
        setStatusOptions(resData?.data?.data || []);

    };

    const getRecruiters = async () => {
        let payload = {
            statusCode: configJSON.requisitionStatusCodes.OPEN,
            subCode: configJSON.requisitionStatusCodes.OPENSUBSTATUS.UNCOVERED,
            recruitmentpartnerId: userRecruitmentpartnerId,
            userType,
            userId: id,
            requisitionId: reqId
        }

        let resp = await getAllRecruiters(payload)
        if (resp?.data?.data?.length > 0)
            setRecruiters(resp?.data?.data)
    }

    useEffect(() => {

        fetchDydamicFields(setLoader, statusOptions, submitDataFromModal, setSubmitDataFromModal, setRequiredfields, orignalData)
        if (submitDataFromModal?.requisitionDetails?.reqreferenceId)
            setIsReferenceSelected(true)

    }, [submitDataFromModal?.statusName])

    useEffect(() => {
        if (isActivity) {
            const getAllData = async () => {
                setLoader(elem => ({ ...elem, loading: true }))
                await getAllCandidateReqStatus()
                if (userType === "Recruiter")
                    await fetchRequisitionsByRecruiterId(id)
                if (userType !== "Recruiter" && page !== "Activity") await getRecruiters()
                // else await SubmitModalOpen(reqId, recId)
                if (userType === "Recruiter" || (page === 'Activity' && userType !== "Recruiter"))
                    await SubmitModalOpen(reqId, id)
                setLoader(elem => ({ ...elem, loading: false }))
            }
            getAllData()
        }
    }, [isActivity])



    // when modal opens fetch canRequisition data or create new 
    const SubmitModalOpen = async (reqid, recid) => {
        if (!reqid)
            return

        setRecId(recid)
        setReqId(reqid)

        const payload = { requisitionId: reqid, email: email, recruitmentpartnerId: userRecruitmentpartnerId }


        let { data, recidInCanReq, teamMatch } = await getCanReq(payload, recid, setCandidateReqId)

        if (userType !== "Recruiter" && page === "Activity") {
            recid = recidInCanReq

        }

        if (recid && (recid !== recidInCanReq || (recid === recidInCanReq && !teamMatch && page !== "Activity"))) {

            NotificationManager.error("candidate already submitted to this requisition", "Error", 4000);
            setOriginalData({})
            setSubmitDataFromModal({})
            setIsReferenceSelected(false)
            setRequiredfields([])
            return
        }

        let resume_date = "", resumeLink = ""
        if (!data.canReqExist) {
            if (row_DataItem?.resumeLink) {
                resumeLink = row_DataItem?.resumeLink;
                resume_date = row_DataItem?.createdAt
            }
            else if (row_DataItem?.powerResumeId) {
                const poweResumeResponse = await profileResponse(row_DataItem?.powerResumeId);
                resumeLink = poweResumeResponse?.data?.data[0]?.resumeUrl || "";
                resume_date = poweResumeResponse?.data?.data?.at(0).lastUpdated
            }

            data = {
                ...data,  // Spread the previous state object to retain other properties
                name: row_DataItem?.name || "",
                email: row_DataItem?.emailID || row_DataItem?.email || "",
                phone: row_DataItem?.phoneNumber || row_DataItem?.phone || "",
                displayDate: DateTime.now().toISODate(),
                resumeLink: resumeLink ? [{ link: resumeLink, uploadedDate: resume_date }] : [],
                candidateResumeDetailsId: row_DataItem?._id || "",
                statusName: 'Submitted',
                requisitionId: reqid,
                statusFreeze: true,
            };

        }

        let reqDetails = await fetchRequisitionsByRecruiterId(recid) || []

        reqDetails = filterFunc(reqDetails, (element, match) => element.requisitionId === match, reqid)[0]?.requisitionDetails

        data = { ...data, requisitionDetails: reqDetails, updated: true }

        setSubmitDataFromModal(data)
        if (data?.requisitionDetails?.reqreferenceId)
            setIsReferenceSelected(true)
        setOriginalData(JSON.parse(JSON.stringify(data || {})))

        // Get the resume data for the selected candidate whenever the submitDataFromModal changes
        if (data?.requisitionId) {
            getResumeData(data)
        }
    }

    // Fetching the resume data for the selected candidate
    const getResumeData = async (data) => {
        setResumeLinkDataLoader(true)
        const resumeLink = data?.resumeLink;
        if (resumeLink[resumeLink.length - 1]?.link.length > 0) {
            setResumeLinkData(resumeLink[resumeLink.length - 1].link);
        } else {
            const response = await candidateResumeById(null, data?.candidateResumeDetailsId);
            setResumeLinkData(response?.data?.data?.resumeUrl);
        }
        setResumeLinkDataLoader(false);
    };


    const activityModalClose = () => {
        setisActivity(false)
        setReqId(props.requisitionIdVal ? props.requisitionIdVal : null)
        setOriginalData({})
        setAllRequisition([])
        setAllRequisitionData([])
        setSubmitDataFromModal({})
        setIsReferenceSelected(false)
        setResumeLinkDataLoader(false)
        setResumeLinkData('')
        setRecId(null)
    }

    const updateSubmitDataState = (field = "", value = "") => {
        if (field === 'statusName') {
            if (value === orignalData?.statusName) {
                setSubmitDataFromModal({ ...JSON.parse(JSON.stringify(orignalData)), updated: false })
                return
            }

        }

        setSubmitDataFromModal(elem => { return { ...elem, [field]: value, updated: false } })
    }


    const submitCandidateReqData = async () => {
        setLoader(elem => ({ ...elem, saving: true }))
        try {
            let formPayload
            if (!submitDataFromModal?.canReqExist) {
                formPayload = verifyForm(formData, requiredFormFields)
                if (!formPayload) {
                    setLoader(elem => ({ ...elem, next: true }))
                    setLoader(elem => ({ ...elem, saving: false }))
                    return
                }
            }

            const requisitionData = allRequisition?.find(item => item?.requisitionId === submitDataFromModal?.requisitionId) || {};

            // Creating resumeLink object for the payload
            let resumeLinkObject;

            if (resumeLinkData) {
                resumeLinkObject = [
                    ...(submitDataFromModal?.resumeLink || []),
                    {
                        link: resumeLinkData,
                        uploadedDate: DateTime.now().toISO()
                    }
                ];
            } else {
                if (submitDataFromModal?.resumeLink?.[0]?.link?.length > 0) {
                    resumeLinkObject = submitDataFromModal?.resumeLink;
                } else {
                    resumeLinkObject = [];
                }
            }

            if (resumeLinkObject?.length > 2)
                resumeLinkObject = resumeLinkObject.slice(resumeLinkObject?.length - 2, resumeLinkObject?.length);


            let payloadData = {
                ...submitDataFromModal,
                requisitionDetailId: requisitionData?.requisitionDetailId || "",
                requisitionLeaderId: requisitionData?.requisitionLeaderId || "",
                requisitionRecruiterId: requisitionData?._id || "",
                requisitionTeamLeaderId: requisitionData?.requisitionTeamLeaderId || "",
                displayDate: DateTime.now().toISO(),
                eventDate: submitDataFromModal.eventDate ? convertDate(submitDataFromModal.eventDate) : null,
                endDate: submitDataFromModal.endDate ? convertDate(submitDataFromModal.endDate) : null,
                candidateStatusCodeId: filterFunc(statusOptions, (elem, match) => elem.name === match.statusName, submitDataFromModal)[0]._id,
                candidateStatusCode: filterFunc(statusOptions, (elem, match) => elem.name === match.statusName, submitDataFromModal)[0].candidateStatusCode,
                salary: submitDataFromModal?.salary || null,
                hourlyMargin: submitDataFromModal?.hourlyMargin || null,
                hourlyPayRate: submitDataFromModal?.hourlyPayRate || null,
                contractualType: submitDataFromModal?.contractualType || undefined,
                resumeLink: resumeLinkObject,
                recruitmentpartnerId: userRecruitmentpartnerId,
                previoussubmissionType: orignalData?.submissionType || null,
                requisitionTeamLeaderUserId: requisitionData?.team?.teamLeaderId || "",
                senderId: requisitionData?.recruiterId,
                userId: idContextTest,
                reqStatusAll: {
                    "reqLead": { "statusCode": requisitionData?.requisitionDetails?.status.statusCode, "subCode": requisitionData?.requisitionDetails?.status.subCode },
                    "reqTl": { "statusCode": requisitionData?.teamleaderrequisitions?.status.statusCode, "subCode": requisitionData?.teamleaderrequisitions?.status.subCode }, "reqRec": { "statusCode": requisitionData?.status.statusCode, "subCode": requisitionData?.status.subCode }
                },
                requisitionRecLeaderUserId: requisitionData?.requisitionDetails?.assignedTo || "",
                requisitionRecUserId: requisitionData?.recruiterId || "",
                teamId: requisitionData?.team?.teamId || "",
                formData: formPayload
            }

            payloadData.placementType = payloadData?.requisitionDetails?.placementType;
            payloadData.reqreferenceId = requisitionData?.requisitionDetails?.reqreferenceId;
            payloadData.requisitionDetails = {}
            payloadData.status = { candidateStatusCodeId: payloadData.candidateStatusCodeId, candidateStatusCode: payloadData.candidateStatusCode }

            let canHasRequisitions = await findCandidatePreviousRequisitionsForClient(submitDataFromModal?.requisitionDetails?.clientId, email, userRecruitmentpartnerId);
            if (canHasRequisitions?.data?.data[0]?.isActive && !submitDataFromModal?.canReqExist) {
                NotificationManager.error("Candidate already assigned to another open requisition from the same client.", "Error", 4500);
                setLoader(elem => ({ ...elem, saving: false }))
                return;
            }

            let userId = requisitionData?.recruiterId

            // Handle existing requisition
            if (submitDataFromModal.canReqExist) {
                let data = createEventStatus(payloadData, userId, requiredFields, id, setLoader)

                payloadData = {
                    data,
                    created: orignalData?.statusName !== submitDataFromModal?.statusName,
                    updated: false,
                    resumeLink: payloadData?.resumeLink,
                    resumeUpdate: payloadData?.resumeUpdate || false,
                    id: payloadData?._id,
                    recruitmentpartnerId: payloadData?.recruitmentpartnerId,
                    status: payloadData?.status,
                    senderId: requisitionData?.recruiterId,
                    previoussubmissionType: orignalData?.submissionType || null,
                    requisitionTeamLeaderUserId: requisitionData?.team?.teamLeaderId || "",
                    userId: idContextTest,
                    reqStatusAll: {
                        "reqLead": { "statusCode": requisitionData?.requisitionDetails?.status.statusCode, "subCode": requisitionData?.requisitionDetails?.status.subCode },
                        "reqTl": { "statusCode": requisitionData?.teamleaderrequisitions?.status.statusCode, "subCode": requisitionData?.teamleaderrequisitions?.status.subCode }, "reqRec": { "statusCode": requisitionData?.status.statusCode, "subCode": requisitionData?.status.subCode }
                    },
                    requisitionRecLeaderUserId: requisitionData?.requisitionDetails?.assignedTo || "",
                    requisitionRecUserId: requisitionData?.recruiterId || "",
                    teamId: requisitionData?.team?.teamId || "",
                    requisitionDetailId: requisitionData?.requisitionDetailId || "",
                    requisitionLeaderId: requisitionData?.requisitionLeaderId || "",
                    requisitionRecruiterId: requisitionData?._id || "",
                    requisitionTeamLeaderId: requisitionData?.requisitionTeamLeaderId || "",
                }
                payloadData.noOfOpenings = requisitionData?.no_of_positions || 0;
                payloadData.totalNoOfOpenings = requisitionData?.requisitionDetails?.openings || 0;
                payloadData.totalNoOfTLOpenings = requisitionData?.teamleaderrequisitions?.no_of_positions || 0;
                payloadData.reqreferenceId = requisitionData?.requisitionDetails?.reqreferenceId;
            } else {
                payloadData.eventStatus = [createEventStatus(payloadData, userId, requiredFields, id, setLoader)];
            }
            // Actual API Call 

            let resp = await (submitDataFromModal.canReqExist ? updateCandidateRequisition(payloadData) : createCandidateRequisition(payloadData));
            moveCandidate(resp?.data?.candidateRequisition, orignalData?.email, orignalData?.statusName, submitDataFromModal?.statusName, false)

            activityModalClose();

            if (resp.status === 200) {
                NotificationManager.success(
                    `${resp.data.message}`,
                    "Success",
                    2500
                );
            }
        } catch (error) {
            const errorMessage = error.response?.data?.errMessage || error?.message || "Failed to submit candidate";
            NotificationManager.error(errorMessage, "Error", 4000);
        }
        setLoader(elem => ({ ...elem, saving: false }))
    }

    // Created a function to render the button based on the text passed
    const renderButton = (text) => (
        <Button
            style={buttonStyles}
            onClick={() => { setShowResumeModal(true) }}
        >
            {text}
        </Button>
    );

    return (
        <>
            <Dialog
                open={isActivity && isResumeUploadLoader != true}
                onClose={activityModalClose}
                aria-labelledby="dialog-title"
                maxWidth="sm"
                fullWidth
                className="add-division"
            >
                <DialogTitle id="form-dialog-title" className={classes.title}>
                    <div className="activit_dialog_header" >
                        <div className="pd_top_10">
                            <img src={EditIcon} alt="Edit Icon" height={35} width={35} />
                            &nbsp;&nbsp;
                        </div>
                        <div><span className="activity_dialog">Action</span></div>
                    </div>
                </DialogTitle>

                <DialogContent>
                    {
                        userType !== "Recruiter" && page !== "Activity" &&
                        <AutoComplete
                            key={`${recruiters?.length}`}
                            title="Recruiters *"
                            options={recruiters || []}
                            getOptionLabel={(option) => option?.email || ""}
                            // value={submitDataFromModal?.recruiterEmail}
                            onChange={async (e, value) => {
                                // updateSubmitDataState('recruiterEmail', value);
                                setLoader(elem => ({ ...elem, loading: true }))
                                setSubmitDataFromModal(null)
                                setIsReferenceSelected(false)
                                await fetchRequisitionsByRecruiterId(value?.recruiterId)
                                setLoader(elem => ({ ...elem, loading: false }))
                            }}
                            className={classes.autocomplete}
                        />
                    }
                    <AutoComplete
                        title="Reference ID *"
                        key={`${submitDataFromModal}+${orignalData?.statusCode}`}
                        options={allRequisition || []}
                        disabled={page === "Activity"}
                        value={submitDataFromModal}
                        getOptionLabel={(option) => option?.requisitionDetails?.reqreferenceId || ""}
                        onChange={async (e, value) => {
                            setLoader(elem => ({ ...elem, loading: true }))
                            await SubmitModalOpen(value?.requisitionDetails?.requisitionId, value?.recruiterId)
                            setLoader(elem => ({ ...elem, loading: false }))
                        }}
                        className={classes.autocomplete}
                    />
                    {/* Status Autocomplete */}

                    <AutoComplete
                        key={`${submitDataFromModal?.statusName}`}
                        title="Status *"
                        options={getFilteredRequisitionStatus(statusOptions, orignalData)}
                        getOptionLabel={(option) => codeTofield(option) || option}
                        value={loader.loading ? "" : submitDataFromModal?.statusName || ""}
                        disabled={submitDataFromModal?.statusFreeze || orignalData?.submissionType === "Internal"}
                        className={classes.autocomplete}
                        onChange={(e, v) => updateSubmitDataState('statusName', v)}
                    />

                    {loader?.loading ? <div className='loaderForEdit'><CircularProgress /></div> :
                        submitDataFromModal && !loader.next ? (
                            <>

                                {requiredFields?.submissionType?.length > 0 &&
                                    <AutoComplete
                                        title="Submission Type *"
                                        options={requiredFields.submissionType}
                                        value={{ type: submitDataFromModal?.submissionType || "" }}
                                        className={classes.autocomplete}
                                        onChange={(e, v) => updateSubmitDataState('submissionType', v?.type)}
                                        getOptionLabel={(option) => option?.type || ""}
                                        disabled={disabled}
                                    />

                                }
                                {
                                    requiredFields?.contractualType?.length > 0 &&
                                    <AutoComplete
                                        title="Contractual Type *"
                                        options={requiredFields.contractualType}
                                        value={submitDataFromModal?.contractualType}
                                        className={classes.autocomplete}
                                        onChange={(e, v) => updateSubmitDataState('contractualType', v)}
                                        disabled={disabled}
                                    />

                                }
                                {
                                    requiredFields?.fields?.map((field) => (
                                        <div key={field.name} className='ActivityDynamicFields' >
                                            <span className='spaceOut'>
                                                {field.name}{' *'}
                                            </span>
                                            {renderField(field, submitDataFromModal, updateSubmitDataState,
                                                DateTime.now().toJSDate(), disabled)}
                                        </div>
                                    ))
                                }
                            </>
                        )
                            :
                            loader.next && formData && <FormModel
                                formData={formData}
                                classes={classes}
                                candidateReqId={candidateReqId}
                                recId={recId}
                                orgId={userRecruitmentpartnerId}
                                setFormData={setFormData}
                                loader={loader}
                                setLoader={setLoader}
                            />
                    }
                    {/* Resume Update and Upload Section */}
                    {
                        <>
                            <div className='spaceOut'>
                                <span>Resume</span>
                                {resumeLinkData?.length > 0 ? (
                                    <div className='resumeLinkContainer'>
                                        {isResumeUploadLoader ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <>
                                                {/* setting icon based on the extension */}
                                                {
                                                    resumeLinkData?.slice(-4) === "docx" || resumeLinkData?.slice(-3) === "doc" ? (
                                                        <>
                                                            <img src={docIcon} alt="doc-icon" style={
                                                                {
                                                                    cursor: 'pointer'

                                                                }
                                                            } onClick={
                                                                () => window.open(resumeLinkData, "_blank")
                                                            } />
                                                        </>
                                                    ) : resumeLinkData?.slice(-3) === "pdf" ? (
                                                        <>
                                                            <img src={pdfIcon} width="39px" alt="pdf-icon" style={
                                                                {
                                                                    cursor: 'pointer'

                                                                }
                                                            } onClick={
                                                                () => window.open(resumeLinkData, "_blank")
                                                            } />
                                                        </>
                                                    ) : (
                                                        "N/A"
                                                    )
                                                }

                                                <div>{renderButton('Update Resume')}</div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {
                                            resumeLinkDataLoader ? (
                                                <div style={{ marginTop: '10px' }}>
                                                    <CircularProgress size={24} />
                                                </div>
                                            ) : (
                                                <>
                                                    {isResumeUploadLoader ? (
                                                        <div style={{ marginTop: '10px' }}>
                                                            <CircularProgress size={24} />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {isReferenceSelected &&
                                                                <div>{renderButton('Upload Resume')}</div>
                                                            }


                                                        </>
                                                    )}
                                                </>
                                            )
                                        }
                                    </div>
                                )}
                            </div>
                        </>
                    }


                </DialogContent>
                <DialogActions className="dialog-actionbutton-division">
                    {!loader?.saving && <button
                        type="button"
                        className="cancel-button-division"
                        onClick={activityModalClose}
                    >
                        Cancel
                    </button>
                    }
                    {
                        !loader.loading && recId && !submitDataFromModal?.canReqExist && <button
                            disabled={loader?.saving || loader?.loading}
                            type="button"
                            className="updateButton-division editButtonAcitivity"
                            onClick={() => {
                                setLoader((elem) => ({ ...elem, next: !elem.next }))
                            }}
                        >
                            <div> {loader.next ? "Fields" : "Form"}</div>
                        </button>
                    }
                    {reqId && submitDataFromModal?.canReqExist && <button
                        disabled={loader?.saving || loader?.loading}
                        type="button"
                        className="updateButton-division editButtonAcitivity"
                        onClick={() => {
                            setEdit(true)
                            setisActivity(false)
                            setRecId(null)
                        }}
                    >
                        <EditSharpIcon />
                        <div> Edit</div>
                    </button>}
                    <button
                        type="button"
                        className='updateButton-division'
                        onClick={submitCandidateReqData}
                        disabled={isResumeUploadLoader || loader?.saving || loader?.loading || submitDataFromModal?.updated} //Disabling the save button when the resume is uploading
                    >
                        Save
                        {loader?.saving && <CircularProgress className='spaceLeft' />}
                    </button>
                </DialogActions>
            </Dialog>
            <Backdrop className={styleClasses.backdrop} open={isResumeUploadLoader}>
                <CircularProgress color="inherit" className="circularprogress_mytalent" />
                <Typography className={styleClasses.text} variant="h6"> Uploading Resume</Typography>
            </Backdrop>
            {/* Upload Resume Modal */}
            {showResumeModal && <UploadResumeModal
                showResumeModal={showResumeModal}
                setShowResumeModal={setShowResumeModal}
                id={idContextTest}
                sendDataToActivityModal={handleDataFromModal}
                handleResumeLoader={handleResumeLoader}
                activityModalUsed={true}
                isSaveModel={true}
                setisActivity={setisActivity}
                userRecruitmentpartnerId={userRecruitmentpartnerId}
                singleFileSelect={true}
            />}

        </>
    )

}

export default ActivityModal;